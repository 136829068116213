import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OrderQueryForm from './OrderQueryForm'
import OrderSearchTable from './OrderSearchTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';
import {jsPDF} from "jspdf";
import 'jspdf-autotable';

function BulkOrderSearch(props) {

    const [vendorList, setVendorList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vendor, setVendor] = useState('');
    const [acumaticaOrderNum, setAcumaticaOrderNum] = useState('');
    const [uploadId, setUploadId] = useState('');
    const [internalPoNumber, setInternalPoNumber] = useState('');
    const [externalPoNumber, setExternalPoNumber] = useState('');
    const [proNumber, setProNumber] = useState('');
    const [sku, setSku] = useState('');
    const [openDetails, setOpenDetail] = useState(null);
    const [orders, setOrders] = useState([]);
    const [detailsTab, setDetailsTab] = useState(null);
    const [direction, setDirection] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');
    const [acumaticaStatus, setAcumaticaStatus] = useState('');
    const [shipmentStatus, setShipmentStatus] = useState('');
    const [warehouse, setWarehouse] = useState('');

    useEffect(() => {
        const getBulkVendors = async() => {
            let vendorList = await vendorHelpers.getBulkVendors(props.company + "_bulk", props.token)
            setVendorList(vendorList.vendors)
        }
        getBulkVendors();
    }, [])

    const clearAllFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setVendor('');
        setAcumaticaOrderNum('');
        setInternalPoNumber('');
        setExternalPoNumber('');
        setSku('');
        setProNumber('');
        setUploadId('');
        setWarehouse('');
        setOrderStatus(null);
        setAcumaticaStatus('');
        setShipmentStatus('');
        setOrders([]);
    }

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`
        eval(func)(event.target.value)
    }

    const handleDateChange = (date, field) => {
        let func = setStartDate;
        if(field === "endDate") func = setEndDate
        if(date !== null){
            func({formatted: date.format("YYYY-MM-DD"), full: date})
        } else {
            func(date)
        }
    }

    const formatOrders = async(orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.internal_po_number;
            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.acu_order_num,
                    address1: order.address1,
                    address2: order.address2,
                    city: order.city,
                    external_po_number: order.external_po_number,
                    internal_po_number: po,
                    name: order.name,
                    order_date: order.order_date,
                    order_status: order.order_status,
                    state: order.state,
                    upload_date: order.upload_date,
                    vendor_status: order.vendor_status,
                    vendor_name: order.vendor_name,
                    zip_code: order.zip_code,
                    shipments: {},
                    items: []
                }
            }
            let tn = order.pro_number;
            if(!(order.pro_number in pos[po].shipments)) {
                pos[po].shipments[tn] = {
                    acu_ship_num: order.acu_ship_num,
                    acumatica_status: order.acumatica_status,
                    carrier: order.carrier,
                    method: order.method,
                    date: order.upload_date,
                    truck_number: order.truck_number,
                    pro_number: tn,
                    shipment_status: order.shipment_status,
                    tote_number: order.tote_number,
                    vendor_status: order.vendor_status,
                    warehouse: order.warehouse,
                    total_on_shipment: 0,
                    pullsheet_id: order.pullsheet_id,
                    items: {}
                }
            }
            let sku = order.internal_sku;
            if(!(sku in pos[po].shipments[tn].items)) {
                pos[po].shipments[tn].items[sku] = {
                    external_sku: order.external_sku,
                    internal_price: order.internal_price,
                    external_price: order.external_price,
                    size: order.size,
                    pa_location: order.pa_location,
                    internal_sku: sku,
                    quantity: 0
                }
            }
            pos[po].items.push({order_id: order.order_id, internal_sku: sku, description: order.description, size: order.size, quantity: order.quantity, pa_location: order.pa_location,})
            pos[po].shipments[tn].items[sku].quantity += order.quantity;
            pos[po].shipments[tn].total_on_shipment += order.quantity
        }
        let out = [];
        let id = 1;
        for(let po in pos) {
            let shipments = [];
            for(let tn in pos[po].shipments) {
                let items = [];
                for(let sku in pos[po].shipments[tn].items) {
                    items.push(pos[po].shipments[tn].items[sku]);
                }
                pos[po].shipments[tn].items = items;
                shipments.push(pos[po].shipments[tn]);
            }
            pos[po].shipments = shipments;
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }

        out.sort((a, b) => {
            let dateA = new Date(a.upload_date);
            let dateB = new Date(b.upload_date);
            return dateB - dateA;
        });

        return out;  
    }

    const getOrders = async () => {
        props.setLoading(true);
        let params = {
            start_date: startDate !== null ? startDate.formatted : null, 
            end_date: endDate !== null ? endDate.formatted : null, 
            vendor_name: vendor !== '' ? vendor : null, 
            acu_order_num: acumaticaOrderNum !== '' ? formatAcuOrderNum(acumaticaOrderNum) : null,
            upload_id: uploadId !== '' ? uploadId : null, 
            internal_po_number: internalPoNumber !== '' ? internalPoNumber : null, 
            external_po_number: externalPoNumber !== '' ? externalPoNumber : null, 
            pro_number: proNumber !== '' ? proNumber : null, 
            sku: sku !== '' ? sku : null,
            order_status: orderStatus !== '' ? orderStatus : null,
            shipment_status: shipmentStatus !== '' ? shipmentStatus : null,
            acumatica_status: acumaticaStatus !== '' ? acumaticaStatus : null,
            warehouse: warehouse !== "" ? warehouse : null,
            limit: 1000,
            order_by: "upload_date",
            desc: true,
            order_valid: (shipmentStatus !== 0 && orderStatus != 199) ? true : null
        }
        console.log(params);
        try {
            let res = await orderHelpers.getBulkOrders(props.company + "_bulk", params, props.token);
            let orders = await formatOrders(res);
            setOrders(orders);
        }
        catch(err) {
            console.log(err);
        }
        
        props.setLoading(false);
    }

    const formatAcuOrderNum = (acuOrderNum) => {
        while (acuOrderNum.length < 7) {
            acuOrderNum = '0' + acuOrderNum;
        }
        return acuOrderNum;
    }

    const setOpenDetails = async(order = {}) => {
        if(Object.keys(order)?.length === 0 ) {
            setOpenDetail(order);
            setDetailsTab(0);
        }
        else {
            setOpenDetail(order.row);
            setDetailsTab(0)
        }
    }

    const setTab = async(e) => {
        console.log(e);
        let tab = Number(e.target.name);
        if(tab > detailsTab) setDirection("left");
        else setDirection("right")
        setDetailsTab(tab);
    }

    const exportOrders = async() => {
        let data = [
            ["acu_order_num", "po_number", "vendor_name", "upload_date", "order_date", "internal_sku", "quantity", "internal_price", "external_price", "zip_code", ].join(",")
        ];
        for(let order of orders) {
            for(let shipment of order.shipments) {
                for(let item of shipment.items) {
                    data.push(
                        [order.acu_order_num, order.internal_po_number, order.vendor_name, order.upload_date, order.order_date, item.internal_sku, item.quantity, item.internal_price, item.external_price, order.zip_code].join(",")
                    )
                }
            }
        }
        let csv = data.join("\n");
        let uri = encodeURI("data:text/csv;charset=utf-8," + csv);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "OrderSearchExport.csv");
        document.body.appendChild(a);
        a.click();
    }

    const createBulkPullsheet = async(order) => {
        props.setLoading(true);
        try {
            const items = order.items;
            const formattedItems = {};
            let total_quantity = 0;
            for (let item of items) {
                if (!(item.internal_sku in formattedItems)) {
                    formattedItems[item.internal_sku] = {
                        internal_sku: item.internal_sku, 
                        description: item.description, 
                        size: item.size, 
                        quantity: 0, 
                        pull_location: item.pa_location
                    }
                } 
                formattedItems[item.internal_sku].quantity += item.quantity;
                total_quantity += item.quantity;
            }
            const sortedItems = Object.values(formattedItems).sort((a, b) => {
                if (!a.pull_location && b.pull_location) return 1;
                if (a.pull_location && !b.pull_location) return -1;
                if (!a.pull_location && !b.pull_location) return 0;
            
                if (a.pull_location < b.pull_location) return -1;
                if (a.pull_location > b.pull_location) return 1;
            
                return 0;
            });
            let pdfItems = [];
            for(let sku in sortedItems) {
                let item = sortedItems[sku];
                pdfItems.push([item?.internal_sku, item?.description, item?.size, item.quantity, item?.pull_location])
            }
            await makePullsheetPDF(order.acu_order_num, order.internal_po_number, order.vendor_name, pdfItems, total_quantity);
        } catch(err) {
            console.log(err);
        }
        props.setLoading(false);
    }

    const makePullsheetPDF = async(acu_order_num, internal_po_number, customer, items, total) => {
        let doc = new jsPDF();

        doc.setFontSize(10);
        let now = new Date();
        let date = now.toISOString().slice(0,10)
        doc.text(180, 15, date);

        const label_x = 10;
        const value_x = 50;
        doc.text(label_x, 15, "Acumatica Order #:");
        doc.text(value_x, 15, acu_order_num);
        doc.text(label_x, 20, "PO #:");
        doc.text(value_x, 20, internal_po_number);
        doc.text(label_x, 25, "Customer:");
        doc.text(value_x, 25, customer);

        doc.setFontSize(16);
        doc.text(15, 50, "Total Pieces: " + String(total));
        doc.autoTable({
            head: [["SKU", "Description", "Size", "Quantity", "Pull Location"]],
            body: items,
            theme: "striped",
            columnStyles: {
                3: {cellWidth: 'auto'}
            },
            startY: 52,
            margin: {top: 15, bottom: 15, left: 15, right: 15},
            headStyles: {
                fillColor: "#187947",
                textColor: "white",
                halign: "center",
                valign: "middle"
            }
        });
        doc.save(acu_order_num + ".pdf");
    }

    return(
        <Box className = {props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <OrderDetails 
                {...props} 
                direction = {direction} 
                setTab = {setTab} 
                tab = {detailsTab} 
                order = {openDetails} 
                setOpenDetails = {setOpenDetails} 
                createBulkPullsheet = {createBulkPullsheet}
            />
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 2}}>
                <h1>Order Search</h1>
                <OrderQueryForm
                    isMobile = {props.isMobile}
                    startDate = {startDate?.full}
                    endDate = {endDate?.full}
                    vendorList = {vendorList} 
                    vendor = {vendor}
                    setVendor = {setVendor}
                    acumaticaOrderNum = {acumaticaOrderNum}
                    internalPoNumber = {internalPoNumber}
                    externalPoNumber = {externalPoNumber}
                    sku = {sku}
                    proNumber = {proNumber}
                    uploadId = {uploadId}
                    warehouse = {warehouse}
                    setWarehouse = {setWarehouse}
                    orderStatus = {orderStatus}
                    setOrderStatus = {setOrderStatus}
                    acumaticaStatus = {acumaticaStatus}
                    setAcumaticaStatus = {setAcumaticaStatus}
                    shipmentStatus = {shipmentStatus}
                    setShipmentStatus = {setShipmentStatus}
                    handleChange = {handleChange}
                    handleDateChange = {handleDateChange}
                    getOrders = {getOrders}
                    orders = {orders}
                    clearAllFilters = {clearAllFilters}
                    exportOrders = {exportOrders} 
                />
                <OrderSearchTable 
                    isMobile = {props.isMobile}
                    orders = {orders}
                    setOpenDetails = {setOpenDetails}
                />
            </Box>
        </Box>
    )
}


export default BulkOrderSearch;